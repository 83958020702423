import React, { FC, useCallback, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';

interface Props {
  onSuccess: (token: string) => void;
  siteKey: string;
};

const FormCaptcha: FC<Props> = ({ onSuccess, siteKey }) => {
  const scriptNode = document.head && !!document.querySelector('script[src="https://www.google.com/recaptcha/api.js"]');

  const successFunctionRef = useRef(uuidv4());
  const expiryFunctionRef = useRef(uuidv4());

  const handleCaptchaExpiry = useCallback(() => onSuccess(''), [onSuccess]);

  useEffect(() => {
    window[successFunctionRef.current] = onSuccess;
  }, [onSuccess]);

  useEffect(() => {
    window[expiryFunctionRef.current] = handleCaptchaExpiry;
  }, [handleCaptchaExpiry]);

  // Segment based on https://github.com/sarneeh/reaptcha/blob/master/lib/utils/injectScript.js
  useEffect(() => {
    const scriptElement: HTMLScriptElement = document.createElement('script');

    scriptElement.async = true;
    scriptElement.defer = true;
    scriptElement.src = 'https://www.google.com/recaptcha/api.js';

    document.head.appendChild(scriptElement);

    return () => {
      document.head.removeChild(scriptElement)
    }
  }, [scriptNode])

  if (!scriptNode || !siteKey) return null;

  return (
    <div
      className="g-recaptcha"
      data-sitekey={siteKey}
      data-callback={successFunctionRef.current}
      data-expired-callback={expiryFunctionRef.current}
      data-expiry
      style={{ margin: '1em 0' }}
    />
  );
};

export default FormCaptcha;
