import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import SubmissionStatusMissing from 'components/submission/status/submission_status_missing';
import { getRequest } from 'utils/api';
import { JsonApiResponse, JsonApiResponseResource } from 'models/api';
import { setCaptchaMeta, setForm } from 'actions/forms';

const FormManager: FC = () => {
  const dispatch = useDispatch();

  const formId = window.location.pathname.replace(/\//g, '');

  const prevFormId = useRef<string>();

  const [formStatus, setFormStatus] = useState('');

  const getForm = useCallback(async (formId: string) => {
    if (formId) {
      setFormStatus('loading');

      await getRequest<JsonApiResponse>(`forms/${formId}`)
        .then((response) => {
          const data = response.data.data as JsonApiResponseResource;

          setFormStatus('success');
          dispatch(setCaptchaMeta(response.data.meta.captcha?.site_key || ''));
          dispatch(setForm(data.id, data.attributes));
        })
        .catch(() => setFormStatus('missing'));
    }
  }, [dispatch])

  useEffect(() => {
    if (formId && (!prevFormId.current || (prevFormId.current !== formId))) {
      getForm(formId);
    }
  }, [getForm, prevFormId, formId]);

  if (formStatus === 'missing' || !formId) {
    return <SubmissionStatusMissing />
  }

  return null;
};

export default FormManager;
