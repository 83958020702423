import { combineReducers } from 'redux';

import fields from 'reducers/fields';
import forms from 'reducers/forms';
import values from 'reducers/values';

const rootReducer = combineReducers({
	fields,
	forms,
	values,
});

export default rootReducer;
