import axios from 'axios';
type RESPONSE_TYPES = 'json';
type REQUEST_TYPES = 'json';
type CONTENT_TYPES = 'application/json' | 'multipart/form-data';

const initialUrlSegment = process.env.NODE_ENV === 'development' ? 'http://localhost:3002/' : '';
const versionUrlSegment = 'v1/';

const generateUrl = (path: string) => `${initialUrlSegment}${versionUrlSegment}${path}`;

/**
 * An Axios Get promise with the provided parameters.
 * @param path Url to be hit, will be appended to default route.
 * @param params Extra url params to be sent in the query / body.
 * @param requestType Type of request, defaults to `json`.
 * @param responseType Type of response, defaults to `json`.
 * @param paramsSerializer Function to be used for serialization, defaults to undefined.
 * @returns A promise for the Axios Get request.
*/
export const getRequest = <T>(
  path,
  params = {},
  requestType: REQUEST_TYPES = 'json',
  responseType: RESPONSE_TYPES = 'json',
  paramsSerializer = undefined
) => (
  axios.get<T>(generateUrl(`${path}.${requestType}`), {
    headers: { 'Cache-Control': 'no-cache' },
    params,
    responseType,
    paramsSerializer
  })
);

/**
 * An Axios Post promise with the provided parameters.
 * @param path Url to be hit, will be appended to default route.
 * @param params Extra url params to be sent in the query / body.
 * @param contentType Type of content to be sent, defaults to 'application/json.
 * @param requestType Type of request, defaults to `json`.
 * @param queryParams Extra params to be sent in the query, defaults to undefined.
 * @param responseType Type of response, defaults to `json`.
 * @param paramsSerializer Function to be used for serialization, defaults to undefined.
 * @returns A promise for the Axios Get request.
*/
export const postRequest = <T>(
  path,
  params = {},
  contentType: CONTENT_TYPES = 'application/json',
  requestType: REQUEST_TYPES = 'json',
  responseType: RESPONSE_TYPES = 'json',
  queryParams = undefined,
  paramsSerializer = undefined
) => (
  axios.post<T>(generateUrl(`${path}.${requestType}`), params, {
    headers: {
      'content-type': contentType,
    },
    responseType,
    paramsSerializer,
    params: queryParams
  })
);
