import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { IState } from 'models/reducer';
import { SchemaRenderer } from 'cgr-form-fields';

import 'stylesheets/app.scss';

import { clearForm } from 'actions/forms';

import FormManager from 'components/form_manager';
import SubmissionSection from 'components/submission/submission_section';
import SubmissionStatusSuccess from 'components/submission/status/submission_status_success';

const schemaRendererClassNames = {
  array: 'panel',
  arrayHeadingPanel: 'panel-heading',
  arrayInstancesContainer: 'panel-content',
  arrayInstanceHeadingPanel: 'array-instance-heading',
  emptyArrayNote: 'note note-primary'
};

const App: FC = () => {
  const formTitle = useSelector((state: IState) => state.forms.title);
  const schema = useSelector((state: IState) => state.forms.schema);

  const formSucceeded = useSelector((state: IState) => state.forms.submissionStatus === 'success');
  const submissionUuid = useSelector((state: IState) => state.forms.latestSubmission?.data.id);
  const submissionCreatedAt = useSelector((state: IState) => state.forms.latestSubmission?.data.attributes.created_at);

  useEffect(() => {
    if (formSucceeded) clearForm();
  }, [formSucceeded])

  return (
    <div>
      <FormManager />

      <div className="form-card">
        <div className="container">
          <div className="row">
            <div className="col-12 form-title">
              <h3>
                {formTitle}
              </h3>
            </div>

            {formSucceeded && submissionUuid && (
              <SubmissionStatusSuccess
                submissionUuid={submissionUuid}
                submissionCreatedAt={submissionCreatedAt}
              />
            )}

            {!formSucceeded && schema && (
              <>
                <SchemaRenderer
                  classNames={schemaRendererClassNames}
                  schema={schema}
                  SubmissionSection={SubmissionSection}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
