import flatten from 'lodash/flatten';

import { Field } from 'models/field';
import { Value } from 'models/value';
import { IFieldState, IValueState } from 'models/reducer';

const stringifyNum = (num) => num >= 10 ? num : `0${num}`;
const ISODateString = (dateVal) => `${dateVal.getFullYear()}-${stringifyNum(dateVal.getMonth() + 1)}-${stringifyNum(dateVal.getDate())}`

const generateValue = (value, field: Field ) => {
  const v = ['date', 'datetime'].includes(field.kind) && value === 'today' ? new Date().toString() : value;

  if (field.kind === 'date') return ISODateString(new Date(v));

  if (field.kind === 'datetime') return new Date(v).toISOString();

  return v;
}

const isNullOrUndefined = (val) => [undefined, null].includes(val);

export const nodeIsHidden = (value: Value, node?: Field) => {
  let isHidden = node?.options?.hidden === true || node?.options?.hidden === 'true'

  Object.values(value.conditionalEffects || {}).forEach((conditionalEffect: any) => {
    const conditionalHidden = conditionalEffect.options?.hidden;

    if (conditionalHidden !== undefined && conditionalHidden !== null) {
      isHidden = conditionalHidden;
    }
  });

  return isHidden;
};

export const parseStateToFormItem = (result, value, values, fields, path: string, field?: Field, valueId: string = '',): any => {
  const currentPath = field && (field.id === fields.startingFieldId || field.type !== 'section') ? field.path.split('.') : '';
  const valuePath = currentPath ? `${path}.${currentPath[currentPath.length - 1]}` : path;

  const hidden = nodeIsHidden(value, field);

  // THIS IS A QUICK FIX FOR THE ABILITY TO HANDLE CONDITIONAL LOGIC IN EXTERNAL FORMS, WILL NEED TO BE HANDLE DIFFERENTLY LATER.
  // external forms use hidden + default to handle stuff like "custom type id", which shouldn't show up as a field but needs to be in the response in order for the item to be valid
  // for that reason, it sends hidden values up, so in order to handle the case of hidden fields which shouldnt actually be sent up, we use this option
  // TODO: refactor external forms so it does defaulting on the backend
  if (hidden && field?.options?.send_when_hidden === false) return result;

  if (value?.children) generateChildrenItems(result, value, values, field, fields, valuePath);

  if (field && field.type === 'field' && valuePath) {
    if (field.kind === 'file' && value.value) {
      let nextAttachmentId = 0;
      for (let key of result.keys()) {
        if (key.includes(`data[attributes][attachments][${nextAttachmentId}][id]`)) nextAttachmentId += 1;
      }

      result.set(generatePath(['data', 'attributes', 'attachments', nextAttachmentId.toString(), 'file']), value.value)
      result.set(generatePath(['data', 'attributes', 'attachments', nextAttachmentId.toString(), 'id']), valueId)
      result.set(generatePath(valuePath.split('.')), valueId);
    } else {
      const formValue = value.value;
      const path = generatePath(valuePath.split('.'));

      if (Array.isArray(formValue)) {
        formValue.forEach((item) => {
          if (!isNullOrUndefined(item)) result.append(`${path}[]`, generateValue(item, field));
        });
      } else {
        if (!isNullOrUndefined(formValue)) result.set(path, generateValue(formValue, field));
      }
    }
  }

  return result;
};

const generateChildrenItems = (result, value, values, field, fields, path) => {
  findAllChildren(field, fields, value, values)?.forEach((childValueId, index) => {
    const childValue = values[childValueId];
    const childField = fields.schema[childValue?.fieldKey];
    let valuePath = path;

    // If there is no field then it is the array instance and the index should be appended for FormData purposes
    // [mytype][0][myfield]
    // [mytype][1][myfield]
    if (!childField) {
      valuePath = `${path}.${index}`;

      // send the parent array id so back end can seperate instances based on there origin
      const parentFieldId = values[childValue.parent].fieldKey;
      result.set(generatePath([...valuePath.split('.'), 'parent_array_id']), parentFieldId);
    }

    parseStateToFormItem(result, childValue, values, fields, valuePath, childField, childValueId);
  });
};

/* There is the possibility of having multiple arrays with the same path
e.g. have 3 different impacts arrays each for a different custom type
1. Find all the arrays with the same path
2. Parse all of them in one go
3. Prevent them from being parsed again */
const findAllChildren = (field, fields: IFieldState, value, values: IValueState) => {
  let children = value.children || [];

  const siblingArrays = field?.type === 'array'
    ? Object.values(fields.schema)
      .filter((f: Field) => {
        // sibling arrays have the same path within the same parent
        if (f.path === field.path && f.parent === field.parent && f.id !== field.id) {

          // only take into account array which have children (array length > 0)
          // We find the arrays value state from the field, then see if it has any children (= array instances)
          const valueKey = (f.valueKeys || [])[0];
          return valueKey && (values[valueKey]?.children?.length || 0) > 0
        }

        return false;
      })
    : null;

    // Accumulate the array of all the items by going through all the siblings and appending the items.
    // To prevent all siblings from being parsed multiple times, we define the alphabetical first item as the one which we parse from.
    if (siblingArrays) {
      const startingArrayId = [field.id, ...siblingArrays.map(x => x.id)].sort()[0]
      if (startingArrayId !== field.id) return null;

      const siblingsChildren = flatten(siblingArrays.map((array) => (
        array.valueKeys?.map((vk) => values[vk].children)
      )));

      children = flatten([...children, ...siblingsChildren]);
    }

    return children;
  }

const generatePath = (path: string[]): string => (
  path.reduce((prev, curr, index) => (
    index === 0 ? curr : `${prev}[${curr}]`
  ), '')
);
