import React, { FC, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';

import { generatePdfDocument } from 'utils/pdf';
import { IState } from 'models/reducer';

interface Props {
  submissionUuid: string;
  submissionExportFooter?: string;
  submissionCreatedAt: string;
}

// pdfMake fetches the data which throws cors error, instead render a virtual image in canvas to get the data url
const fetchImage = (url: string): Promise<string> => (
  new Promise((resolve, reject) => {
    const image = new window.Image();
    image.crossOrigin = 'anonymous';

    image.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = image.naturalWidth;
      canvas.height = image.naturalHeight;
      canvas.getContext('2d')?.drawImage(image, 0, 0);
      resolve(canvas.toDataURL('image/png'));
    };

    image.onerror = () => { reject(); };

    image.src = url;
  })
);

const PdfButton: FC<Props> = ({
  submissionUuid,
  submissionExportFooter,
  submissionCreatedAt
}) => {
  const formTitle = useSelector((state: IState) => state.forms.title);
  const startingFieldId = useSelector((state: IState) => state.fields.startingFieldId);
  const clientLogo = useSelector((state: IState) => state.forms.clientLogo);
  const schema = useSelector((state: IState) => state.fields.schema);
  const fieldValues = useSelector((state: IState) => state.values.fieldValues);

  const fileName = `${submissionCreatedAt}-${formTitle.slice(0, 50)}.pdf`;

  const [pdfImageUrl, setPdfImageUrl] = useState<string | undefined>();

  useEffect(() => {
    if (clientLogo) {
      fetchImage(clientLogo)
        .then((val: string) => {
          setPdfImageUrl(val);
        })
        .catch(() => {
          // if image throws cors error, then still download pdf just without the image
          setPdfImageUrl(undefined);
        });
    }
  }, [clientLogo])

  const createPdf = useCallback((clientLogoDataUrl?: string) => {
    (pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

    (pdfMake as any).tableLayouts = {
      array: {
        hLineWidth: (i: number, node: any) => (i === 0 || i === node.table.body.length) ? 1 : 0,
        vLineWidth: (i: number, node: any) => i === 0 || i === node.table.widths.length ? 1 : 0,
        hLineColor: '#ccc',
        vLineColor: '#ccc'
      },
      section: {
        hLineWidth: (i: number) => i === 1 ? 1 : 0,
        vLineWidth: () => 0,
        hLineColor: '#ccc',
      }
    };

    return generatePdfDocument(schema, fieldValues, startingFieldId, formTitle, submissionUuid, submissionCreatedAt, clientLogo, clientLogoDataUrl, submissionExportFooter);
  }, [clientLogo, fieldValues, formTitle, schema, startingFieldId, submissionCreatedAt, submissionExportFooter, submissionUuid]);

  const handleDownloadClick = useCallback(() => {
    const pdfDocument = createPdf(pdfImageUrl);

    if (pdfDocument) {
      const pdf = pdfMake.createPdf(pdfDocument);
      pdf.download(fileName);
    }
  }, [createPdf, fileName, pdfImageUrl]);

  return (
    <button
      className="btn btn-sm btn-primary"
      onClick={handleDownloadClick}
      style={{ marginTop: '1em' }}
    >
      Download PDF Receipt
    </button>
  );
}

export default PdfButton;
