import { Field } from 'models/field';
import { Value } from 'models/value';
import { StyleDictionary, Content, TDocumentDefinitions } from 'pdfmake/interfaces';

import { nodeIsHidden } from 'utils/form';
import { generateItemLabel, generateArrayTypeName } from 'utils/fields';

type Option = {
  id: string | number;
  title: string;
}

const asNumber = (id: string | number) => typeof id === 'string' ? parseInt(id) : id;

const compareLoookupOption = (val: number | string | Array<number | string>, optionId: string | number): boolean => {
  if (Array.isArray(val)) {
    return val.map((v) => asNumber(v)).includes(asNumber(optionId));
  }

  return asNumber(optionId) === asNumber(val);
};

const mutedColor = '#999';

const styles: StyleDictionary = {
  footer: {
    fontSize: 8,
    margin: 20,
    color: mutedColor,
    alignment: 'center'
  },
  heading: {
    fontSize: 12,
    margin: 5,
    bold: true,
  },
  value: {
    fontSize: 10,
    margin: [15, 0, 0, 0]
  },
  blankValue: {
    fontSize: 10,
    margin: [15, 0, 0, 0],
    color: mutedColor
  },
  table: {
    margin: 5
  },
  arrayHead: {
    fontSize: 12,
    margin: 5,
    bold: true
  },
  title: {
    fontSize: 18,
    alignment: 'center',
    margin: [0, 0, 0, 15],
    bold: true
  },
  subtitle: {
    fontSize: 10,
    color: mutedColor,
    alignment: 'center'
  }
};


export const generatePdfDocument = (
  schema:  Record<string, Field>,
  fieldValues: Record<string, Value>,
  startingFieldId: string,
  title: string,
  uuid: string,
  submissionCreatedAt: string,
  clientLogo?: string,
  clientLogoDataUrl?: string,
  footer?: string,
): TDocumentDefinitions | undefined => ({
  footer: footer
    ? {
      style: 'footer',
      text: footer,
    }
    : '',
  styles,
  images: clientLogo && clientLogoDataUrl ? { clientLogo: clientLogoDataUrl } : {},
  content: [
    clientLogo && clientLogoDataUrl ? { image: 'clientLogo' } : '',
    {
      style: 'title',
      text: title || ''
    },
    {
      style: 'subtitle',
      text: submissionCreatedAt
    },
    {
      style: 'subtitle',
      text: 'Your reference for this form is:'
    },
    {
      style: 'subtitle',
      text: uuid
    },
    generatePdfNode(startingFieldId, schema, fieldValues)
  ]
});

export const generatePdfNode = (fieldId: string, schema:  Record<string, Field>, fieldValues: Record<string, Value>, arrayIndex?: number): Content => {
  const node = schema[fieldId];

  const valueId = (node.valueKeys || [])[arrayIndex || 0];

  if (!valueId) {
    return '';
  }

  const value = fieldValues[valueId];

  if (!value) {
    return '';
  }

  if(nodeIsHidden(value, node)) return '';

  switch(node.type) {
    case 'field':
      return generatePdfFieldNode(node, value);

    case 'section':
      return generatePdfSectionNode(node, schema, fieldValues);

    case 'array':
      return generatePdfArrayNode(node, schema, fieldValues, value);
  }
};

const array = (title: string, children: Content) => {
  return {
    style: 'table',
    layout: 'array',
    table: {
      widths: ['*'],
      body: [
        [{
          fillColor: '#ddd',
          text: title,
          style: 'arrayHead'
        }],
        [children]
      ]
    }
  };
};

const section = (title: string, children: Content) => (
  {
    style: 'table',
    layout: 'section',
    table: {
      widths: ['*'],
      body: [
        [title],
        [children]
      ]
    }
  }
);

const generatePdfArrayInstance = (field: Field, schema: Record<string, Field>, fieldValues: Record<string, Value>, valueId: string,  arrayIndex: number): Content => {
  const typeName = generateArrayTypeName(field);

  const label =  `${typeName} #${(arrayIndex || 0) + 1}`;

  const children = field.children.map((childId) => (
    generatePdfNode(childId, schema, fieldValues, arrayIndex)
  ));

  return section(label, children);
};

const generatePdfArrayNode = (field: Field,  schema: Record<string, Field>, fieldValues: Record<string, Value>, value: Value) => {
  const label = generateItemLabel(field) || '';

  const children = value.children?.map((childId, arrayIndex) => generatePdfArrayInstance(field, schema, fieldValues, childId, arrayIndex));

  return (array(label, children || { style: 'value', text: ' - ' }));
};

const generatePdfSectionNode = (field: Field, schema: Record<string, Field>, fieldValues: Record<string, Value>): Content => {
  const label = generateItemLabel(field) || '';

  const childPdfNodes = field.children.map((childId) => generatePdfNode(childId, schema, fieldValues));

  return section(label, childPdfNodes);
};


const generatePdfFieldNode = (field: Field, value: Value): Content => {
  const label = generateItemLabel(field) || '';

  let isHidden = false

  Object.values(value.conditionalEffects || {}).forEach((conditionalEffect: any) => {
    const conditionalHidden = conditionalEffect.options?.hidden;

    if (conditionalHidden === true) {
      isHidden = true;
    }
  });

  if (isHidden) return '';

  const v = value.value;
  let content: string | string[] = '';

  if (typeof v === 'number' || typeof v === 'string') {
    content = `${v}`;
  }

  if (v instanceof Date) {
    if (field.kind === 'date') {
      content = v.toLocaleDateString();
    } else {
      // datetime
      content = v.toLocaleString();
    }
  }

  if (v instanceof File) {
    content = v.name;
  }

  if (v instanceof FileList) {
    content = [...Array(v.length).keys()]
      .map((i) => v.item(i)?.name || '')
      .filter((s) => !!s);
  }

  if (['lookup', 'item_lookup'].includes(field.kind)  && value.value) {
    if (Array.isArray(v)) {
      const selectedOptions = field.meta?.options?.filter((x: Option) =>  compareLoookupOption(v, x.id)) || [];
      content = selectedOptions.map((x) => x.title).join(', ');
    } else if (typeof v === 'number' || typeof v === 'string') {
      content = field.meta?.options?.find((x: Option) => compareLoookupOption(v, x.id)).title;
    }
  }

  if (Array.isArray(content)) {
    return [
      {
        style: 'heading',
        text: label
      },
      {
        ul: content
      }
    ];
  }

  return [
    {
      style: 'heading',
      text: label
    },
    content
      ? { style: 'value', text: content }
      : { style: 'blankValue', text: ' - ' }
  ];
};
