import React, { FC } from 'react';

import { Error } from 'models/api';
import submissionStatusStyles from 'components/submission/status/submission_status.module.scss';
import SubmissionStatusBase from 'components/submission/status/submission_status_base';

interface Props {
  errors?: Error[]
}

const SubmissionStatusFailure: FC<Props> = ({ errors }) => (
  <SubmissionStatusBase status="failed">
    <span className={submissionStatusStyles.errors}>
      <ul className={submissionStatusStyles.errorsList}>
        {errors?.map((e) => (
          <li key={e.title}>
            <span className={submissionStatusStyles.errorTitle}>
              {e.title}:
            </span>

            {e.detail}
          </li>
        ))}
      </ul>

    </span>
  </SubmissionStatusBase>
);

export default SubmissionStatusFailure;
