import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import { Provider } from 'react-redux';

import React from 'react';
import ReactDOM from 'react-dom';
import App from 'components/App';
import reportWebVitals from 'reportWebVitals';
import configureStore from 'config/store';
import rootReducer from 'reducers/index';

import 'globals';
import 'index.css';

require('formdata-polyfill');

const store = configureStore({
  ...rootReducer
});

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
