import React, { FC } from 'react';
import submissionStatusStyles from 'components/submission/status/submission_status.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

interface Props {
  children?: React.ReactNode;
  status: 'success' | 'failed';
  submissionCreatedAt?: string;
}

const SubmissionStatusBase: FC<Props> = ({
  children,
  status,
  submissionCreatedAt
}) => (
  <div className={submissionStatusStyles.submissionDiv}>
    <h3 className={submissionStatusStyles[status]}>
      <FontAwesomeIcon
        icon={status === 'success' ? faCheckCircle : faTimesCircle}
        style={{ marginRight: '0.3rem' }}
      />

      {`${status[0].toUpperCase()}${status.slice(1)}`}
    </h3>

    {children}

    <span className={submissionStatusStyles.dateString}>
      {submissionCreatedAt || new Date().toLocaleString()}
    </span>
  </div>
);

export default SubmissionStatusBase;
