import React, { FC } from 'react';

import { IValueState } from 'models/schema_renderer';
import TermsCheckbox from 'components/terms/terms_checkbox';
import TermsText from 'components/terms/terms_text';


interface Props {
  values: IValueState,
  agreeToTerms: boolean,
  handleChangeTerms: (name: string, value: boolean) => void
}

const TermsSection: FC <Props> = ({
  values,
  agreeToTerms,
  handleChangeTerms
}) => (
  <div className="panel">
    <div className="panel-heading">
      <h3>
        Terms and Conditions
      </h3>
    </div>

    <div className="panel-content">
      <TermsText />

      <TermsCheckbox
        values={values}
        agreeToTerms={agreeToTerms}
        handleChangeTerms={handleChangeTerms}
      />
    </div>
  </div>

);

export default TermsSection;
