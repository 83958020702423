import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { IState } from 'models/reducer';
import SubmissionStatusBase  from 'components/submission/status/submission_status_base';
import submissionStatusStyles from 'components/submission/status/submission_status.module.scss';
import PdfButton from 'components/pdf/pdf_button';

interface Props {
  submissionUuid: string,
  submissionCreatedAt: string;
}

const SubmissionStatusSuccess: FC<Props> = ({
  submissionCreatedAt,
  submissionUuid
}) => {
  const submissionNote = useSelector((state: IState) => state.forms.submissionNote)
  const submissionExportEnabled = useSelector((state: IState) => state.forms.submissionExportEnabled);
  const submissionExportFooter = useSelector((state: IState) => state.forms.submissionExportFooter);

  return (
    <SubmissionStatusBase status="success" submissionCreatedAt={submissionCreatedAt}>
      {submissionNote && (
        <>
          <span className={submissionStatusStyles.submissionNoteHeading}>Form Note:</span>

          <div className={submissionStatusStyles.submissionNote}>
            <span>{submissionNote}</span>
          </div>
        </>
      )}

      <span>Your reference for this form is:</span>

      <span className={submissionStatusStyles.token}>
        {submissionUuid}
      </span>

      {submissionExportEnabled && (
        <PdfButton
          submissionUuid={submissionUuid}
          submissionExportFooter={submissionExportFooter}
          submissionCreatedAt={submissionCreatedAt}
        />
      )}
    </SubmissionStatusBase>
  );
};

export default SubmissionStatusSuccess;
