import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import buttonStyles from 'components/buttons/buttons.module.scss';
import { handleFormSubmitFailure, handleFormSubmitSuccess } from 'actions/forms';
import { postRequest } from "utils/api";
import { parseStateToFormItem } from 'utils/form';
import { JsonApiResponse } from 'models/api';
import { IFieldState, IValueState } from 'models/schema_renderer';
import { setValuesState } from 'actions/values';
import { setFieldsState } from 'actions/fields';

interface Props {
  fields: IFieldState;
  values: IValueState,
  agreeToTerms: boolean,
  captchaToken: string,
  handleSetShowErrors: () => void;
  handleFocusError: (error: string) => void;
  setIsLoading: (isLoading: boolean) => void
};

const SubmitButton: FC<Props> = ({
  fields,
  handleSetShowErrors,
  handleFocusError,
  values,
  agreeToTerms,
  captchaToken,
  setIsLoading
}) => {
  const dispatch = useDispatch();

  const formId = window.location.pathname.replace(/\//g, '');

  const sendForm = useCallback(async (values: FormData) => {
    await postRequest<JsonApiResponse>(`form_submissions/${formId}`, values, 'multipart/form-data')
      .then((response) => {
        setIsLoading(false);
        dispatch(handleFormSubmitSuccess(response.data));
      })
      .catch((e: { response: JsonApiResponse }) => {
        let errorMessages;
        setIsLoading(false);

        if (e.response?.data) {
          if (Array.isArray(e.response.data)) {
            errorMessages = e.response.data[0].errors
          } else {
            errorMessages = e.response.data.errors;
          }
        }

        dispatch(handleFormSubmitFailure(errorMessages));
      });
  }, [dispatch, formId, setIsLoading]);


  const handleSubmit = useCallback(() => {
    const errors = Object.entries(values?.errors)?.filter((v) => v[1].key);
    const errorsPresent = errors.length > 0;

    if (errorsPresent || !agreeToTerms || !captchaToken) {
      handleSetShowErrors();

      if (errorsPresent) {
        handleFocusError(errors[0][0]);
      }

      return;
    }

    setIsLoading(true);

    // set the state of the field and values reducer to that of the form, so that the pdf generator has access to it
    dispatch(setValuesState(values));
    dispatch(setFieldsState(fields));

    const startingFieldId = fields.startingFieldId;
    const startingField = fields.schema[startingFieldId];
    const startingValue = Object.values(values.fieldValues).filter((value: any) => value.fieldKey === startingFieldId)[0]

    const result = parseStateToFormItem(new FormData(), startingValue, values.fieldValues, fields, 'data.attributes.data', startingField);

    result.append('data[attributes][terms]', agreeToTerms);
    result.append('data[attributes][token]', captchaToken);

    return sendForm(result);
  }, [values, agreeToTerms, captchaToken, setIsLoading, dispatch, fields, sendForm, handleSetShowErrors, handleFocusError]);

  return (
    <div className={buttonStyles.buttonsPanel}>
      <button
        className={`btn btn-sm btn-primary ${buttonStyles.submitButton}`}
        disabled={!values.hasChanged}
        onClick={handleSubmit}
      >
        Submit
      </button>
    </div>
  );
}

export default SubmitButton;
