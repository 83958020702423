import { IFieldState } from 'models/reducer';
import { SET_FIELDS_STATE, FieldActions } from 'actions/fields';

const initialState: IFieldState = {
  isValid: false,
  startingFieldId: '',
  schema: {},
  schemaLoaded: false
};

const fieldReducer = (
  state = initialState,
  action: FieldActions
): IFieldState => {
  switch(action.type) {
    case SET_FIELDS_STATE:
      return action.fieldState;

    default:
      return state;
  }
}

export default fieldReducer;
