import { JsonApiResponse, Error } from "models/api";

export const CLEAR_FORM = 'CLEAR_FORM';
export const SET_CAPTCHA_META = 'SET_CAPTCHA_META';
export const SET_FORM = 'SET_FORM';
export const HANDLE_FORM_SUBMIT_SUCCESS = 'HANDLE_FORM_SUBMIT_SUCCESS';
export const HANDLE_FORM_SUBMIT_FAILURE = 'HANDLE_FORM_SUBMIT_FAILURE';

interface ClearForm {
  type: typeof CLEAR_FORM;
}

interface SetCaptchaMeta {
  type: typeof SET_CAPTCHA_META;
  siteKey: string;
}

interface SetForm {
  type: typeof SET_FORM,
  id: string;
  clientLogo?: string;
  schema: object;
  submissionNote?: string;
  submissionExportEnabled?: boolean;
  submissionExportFooter?: string;
  title: string;
}

interface HandleFormSubmitSuccess {
  type: typeof HANDLE_FORM_SUBMIT_SUCCESS;
  response:  JsonApiResponse;
}

interface HandleFormSubmitFailure {
  type: typeof HANDLE_FORM_SUBMIT_FAILURE,
  errors: Error[]
}

export const handleFormSubmitSuccess = (response: JsonApiResponse) => ({ type: HANDLE_FORM_SUBMIT_SUCCESS, response });

export const handleFormSubmitFailure = (errors: Error[]) => ({ type: HANDLE_FORM_SUBMIT_FAILURE, errors })

export const clearForm = () => ({ type: CLEAR_FORM });

export const setCaptchaMeta = (siteKey: string) => ({
  type: SET_CAPTCHA_META,
  siteKey
});

export const setForm = (id: string, formAttributes:  Record<string, any>) => ({
  type: SET_FORM,
  clientLogo: formAttributes.client_logo,
  id,
  schema: formAttributes.schema,
  submissionExportEnabled: formAttributes.submission_export_enabled,
  submissionExportFooter: formAttributes.submission_export_footer,
  submissionNote: formAttributes.submission_note,
  title: formAttributes.title
});

export type FormActions = ClearForm | HandleFormSubmitSuccess | HandleFormSubmitFailure | SetCaptchaMeta | SetForm;
