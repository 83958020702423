import { IValueState } from "models/schema_renderer";
import { SET_VALUES_STATE, ValueActions } from 'actions/values';

const initialState: IValueState = {
  errors: {},
  fieldValues: {},
  hasChanged: false,
  submitted: false,
  showErrors: false,
};

const valueReducer = (
  state = initialState,
  action: ValueActions
): IValueState => {
  switch(action.type) {
    case SET_VALUES_STATE:
      return action.valueState;

    default:
      return state;
  }
}

export default valueReducer;
