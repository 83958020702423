import { applyMiddleware, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension'


import monitorReducersEnhancer from 'enhancers/monitorReducer';
import loggerMiddleware from 'middleware/logger';
import rootReducer from 'reducers';

export default function configureStore(preloadedState:any) {  
  let middleware: any[] = [thunkMiddleware]
  if (process.env.NODE_ENV !== 'production') {
    middleware = [...middleware, loggerMiddleware];
  }

  const middlewareEnhancer = applyMiddleware(...middleware);

  let enhancers: any[] = [middlewareEnhancer];
  if (process.env.NODE_ENV !== 'production') {
    enhancers = [...enhancers, monitorReducersEnhancer];
  }

  const composedEnhancers = composeWithDevTools(...enhancers);

  const store = createStore(rootReducer, preloadedState, composedEnhancers);

  return store;
};
