import React, { FC } from 'react';

import { CheckboxField } from 'cgr-form-fields';
import { IValueState } from 'models/schema_renderer';

interface Props {
  values: IValueState,
  agreeToTerms: boolean,
  handleChangeTerms: (name: string, value: boolean) => void
}

const termsErrorBlock = (
  <p className="error-block">
    You must agree to the Terms and Conditions before proceeding.
  </p>
);

const TermsCheckbox: FC <Props> = ({
  values,
  agreeToTerms,
  handleChangeTerms
}) => (
  <CheckboxField
    label="I am at least 18 years of age and I have read, accepted, and agreed to the Terms of Use."
    error={values.showErrors && !agreeToTerms}
    below={values.showErrors && !agreeToTerms ? termsErrorBlock : null}
    onChange={handleChangeTerms}
    required
    name="terms"
    value={agreeToTerms}
  />
);

export default TermsCheckbox;
