import {
  CLEAR_FORM,
  HANDLE_FORM_SUBMIT_FAILURE,
  HANDLE_FORM_SUBMIT_SUCCESS,
  FormActions,
  SET_CAPTCHA_META,
  SET_FORM
} from 'actions/forms';

import { IFormState } from 'models/reducer';

const initialState: IFormState = {
  captchaSiteKey: '',
  clientLogo: undefined,
  id: '',
  schema: undefined,
  submissionCreatedAt: undefined,
  submissionErrors: [],
  submissionExportEnabled: false,
  submissionExportFooter: undefined,
  submissionNote: '',
  submissionStatus: undefined,
  latestSubmission: undefined,
  title: ''
};

const FormsReducer = (
  state = initialState,
  action: FormActions
): IFormState => {
  switch(action.type) {
    case HANDLE_FORM_SUBMIT_FAILURE:
      return {
        ...state,
        submissionStatus: 'failed',
        submissionErrors: action.errors,
        latestSubmission: undefined
      };

    case HANDLE_FORM_SUBMIT_SUCCESS:
      return {
        ...state,
        submissionStatus: 'success',
        submissionErrors: [],
        latestSubmission: action.response,
      };

    case SET_FORM:
      return {
        ...state,
        id: action.id,
        clientLogo: action.clientLogo,
        schema: action.schema,
        submissionNote: action.submissionNote,
        submissionExportEnabled: action.submissionExportEnabled || false,
        submissionExportFooter: action.submissionExportFooter,
        title: action.title
      };

    case SET_CAPTCHA_META:
      return {
        ...state,
        captchaSiteKey: action.siteKey
      };

    case CLEAR_FORM:
      return initialState;

    default:
      return state;
  }
};

export default FormsReducer;
