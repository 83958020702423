import React, { FC, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { IState } from 'models/reducer';
import { IValueState, IFieldState } from 'models/schema_renderer';

import styles from 'components/submission/submission_section.module.scss';
import TermsSection from 'components/terms/terms_section';
import SubmissionStatusFailure from 'components/submission/status/submission_status_failure';
import FormCaptcha from 'components/submission/form_captcha';
import SubmitButton from 'components/buttons/submit_button';

interface Props {
  fields: IFieldState;
  values: IValueState;
  handleSetShowErrors: () => void;
  handleFocusError: (invalidNodeId: string) => void;
}

const SubmissionSection: FC<Props> = ({
  fields,
  values,
  handleSetShowErrors,
  handleFocusError
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [captchaToken, setCaptchaToken] = useState('');

  const submissionFailed = useSelector((state: IState) => state.forms.submissionStatus === 'failed');
  const submissionErrors = useSelector((state: IState) => state.forms.submissionErrors);

  const handleChangeTerms = useCallback((_name, value) => {
    setAgreeToTerms(value);
  }, [setAgreeToTerms]);

  const handleCaptchaSuccess = useCallback((token) => {
    setCaptchaToken(token);
  }, []);

  const [captchaRefreshKey, setCaptchaRefreshKey] = useState<number>(0)

  useEffect(() => {
    if (!isLoading && submissionFailed) {
      setCaptchaRefreshKey((prev) => prev + 1);
      setCaptchaToken('');
    }
  }, [isLoading, submissionFailed])


  const captchaSiteKey = useSelector((state: IState) => state.forms.captchaSiteKey);

  return (
    <div>
      {isLoading && <div className={styles.loadingOverlay}/> }

      <TermsSection
        values={values}
        agreeToTerms={agreeToTerms}
        handleChangeTerms={handleChangeTerms}
      />

      <div className={styles.captchaPanel}>
        <FormCaptcha
          key={captchaRefreshKey}
          onSuccess={handleCaptchaSuccess}
          siteKey={captchaSiteKey}
        />
      </div>

      {submissionFailed && (
        <SubmissionStatusFailure
          errors={submissionErrors}
        />
      )}

      <SubmitButton
        handleSetShowErrors={handleSetShowErrors}
        handleFocusError={handleFocusError}
        values={values}
        fields={fields}
        agreeToTerms={agreeToTerms}
        captchaToken={captchaToken}
        setIsLoading={setIsLoading}
      />
    </div>
  );
}

export default SubmissionSection;
